.main-home {
    background: url('images/background/accueil/main-home.jpg');
    background-size: cover;

    .page-content {
        .pseudo-card {
            border-radius: $bdrs-normal;
        }

        .top {
            padding: 40px;

            @media screen and (max-width: 400px) {
                padding: 40px 0;
            }


            h1, h2 {
                margin: 0;
                color: #ffffff;

                @media screen and (max-width: 400px) {
                    padding: 0 40px;
                }
            }

            h1 {
                padding-bottom: 80px;

                @include respond-to('m-768') {
                    padding-bottom: 56px;
                }
            }

            h2 {
                padding-bottom: 24px;
            }

            .card-container {
                @include flex(row, stretch, flex-start);
                gap: 40px;

                @include respond-to('t-992') {
                    flex-wrap: wrap;
                }

                .pseudo-card {
                    @include flex(column, stretch, space-between);
                    padding: 40px;
                    background: rgba(31, 68, 113, 0.7);
                    flex: 1 3 33%;

                    @include respond-to('t-992') {
                        flex: 1 3 34%;
                    }

                    p {
                        margin: 0;
                        padding-bottom: 40px;
                        color: #ffffff;

                        & + p {
                            padding-bottom: 0;

                            a {
                                display: flex;
                                width: 100%;
                                text-decoration: none;

                                md-elevated-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            background: #ffffff;
            padding: 40px;

            @include respond-to('m-768') {
                padding: 40px 0;
            }

            h2 {
                margin-top: 0;

                @include respond-to('m-768') {
                    padding: 0 40px;
                }
            }

            .bdrs {
                border-radius: $bdrs-normal;

                @include respond-to('m-768') {
                    box-shadow: initial;
                    border-radius: initial;
                }

                &:has(.oops) {
                    box-shadow: initial;
                    border-radius: initial;
                    width: fit-content;

                    @include respond-to('m-768') {
                        padding-left: 40px;
                    }
                }
            }
        }
    }
}
