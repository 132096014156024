.carriers-main-page {
    .page-content {
        padding: 0 0 40px 0;

        header {
            background-image: url('images/background/carriers.jpeg');
            background-size: cover;
            background-position-x: right;
            background-color: #17375f;
            color: #ffffff;

            .intro {
                padding: 40px;

                h1 {
                    padding-bottom: 24px;
                }

                .arguments {
                    @include flex(column, flex-start, flex-start);

                    p {
                        gap: 8px;
                        margin: 0;
                        padding-bottom: 8px;
                        @include flex(row, flex-start, flex-start);

                        &:last-child {
                            padding-bottom: 0;
                        }

                        md-icon,
                        span {
                            display: inline-block;
                            min-height: 28px;
                            line-height: 28px;


                        }
                    }
                }
            }

            .funnel {
                padding: 0 40px 40px 40px;

                @include respond-to('m-768') {
                    padding: 0 0 40px 0;
                }

                .bdrs {
                    background-color: #fff;
                    border-radius: $bdrs-normal;

                    @include respond-to('m-768') {
                        border-radius: initial;
                    }
                }
            }

        }

        .carriers {
            padding: 40px;

            form {
                padding-bottom: 40px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;

                @include respond-to('m-768') {
                    grid-template-columns: initial;
                    grid-template-rows: 1fr 1fr;
                    grid-gap: 32px;
                }

                .custom-select {
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        pointer-events: none;
                        width: 8px;
                        height: 8px;
                        border-bottom: 2px solid rgba(0, 0, 0, 0.87);
                        border-right: 2px solid rgba(0, 0, 0, 0.87);
                        transform: rotate(45deg);
                        position: absolute;
                        right: 16px;
                        top: 20px;
                    }
                }

                select {
                    width: 100%;
                    padding: 16px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
                }
            }

            ul {
                margin: 0;
                padding: 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                //row-gap: 40px;
                //column-gap: 40px;

                @include respond-to('t-992') {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include respond-to('m-768') {
                    grid-template-columns: 1fr;
                }

                li {
                    list-style-type: none;
                    padding: 0;

                    &[data-order^="item-"] {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-1"],
                    &[data-order="item-4"],
                    &[data-order="item-7"],
                    &[data-order="item-10"],
                    &[data-order="item-13"] {
                        padding: 20px 20px 20px 0;
                        border-right: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-2"],
                    &[data-order="item-5"],
                    &[data-order="item-8"],
                    &[data-order="item-11"],
                    &[data-order="item-14"] {
                        padding: 20px;
                        border-right: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-3"],
                    &[data-order="item-6"],
                    &[data-order="item-9"],
                    &[data-order="item-12"] {
                        padding: 20px 0 20px 20px;
                    }

                    @include respond-to('t-992') {
                        &[data-order^="item-"] {
                            border-right: none;
                            padding: 0;
                        }

                        &[data-order="item-1"],
                        &[data-order="item-3"],
                        &[data-order="item-5"],
                        &[data-order="item-7"],
                        &[data-order="item-9"],
                        &[data-order="item-11"],
                        &[data-order="item-13"] {
                            padding: 20px 20px 20px 0;
                            border-right: 1px solid rgba(0, 0, 0, 0.37);
                        }

                        &[data-order="item-2"],
                        &[data-order="item-4"],
                        &[data-order="item-6"],
                        &[data-order="item-8"],
                        &[data-order="item-10"],
                        &[data-order="item-12"],
                        &[data-order="item-14"] {
                            padding: 20px 0 20px 20px;
                        }

                    }

                    @include respond-to('m-768') {
                        &[data-order^="item-"] {
                            border-right: none;
                            border-bottom: none;
                            padding: 0 0 40px 0;
                        }
                    }

                    a {
                        text-decoration: none;
                        height: 100%;
                        @include flex(column, flex-start, space-between);

                        .carrier-text {
                            h2 {
                                margin-top: 0;
                            }

                            .logo {
                                img {
                                    max-height: 40px;
                                }
                            }
                        }

                        ul {
                            display: initial;

                            li {
                                padding: 0;
                                list-style-type: disc;
                                list-style-position: inside;
                                border: none !important;
                            }
                        }

                        .more {
                            @include flex(row, center, flex-start);
                            gap: 8px;
                        }
                    }

                    &.hide {
                        display: none;
                    }
                }
            }
        }

        .partners {
            padding: 40px;
            background-color: #f3f4f7;

            h2 {
                margin-top: 0;
            }
        }

        .ecom {
            padding: 40px;

            h2 {
                margin-top: 0;
            }

            ul {
                padding: 24px 0 0 0;
                margin: 0;
                list-style: none;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 40px;
                grid-row-gap: 24px;
                align-items: start;

                @include respond-to('m-768') {
                    display: initial;
                }

                li {
                    @include flex(column, flex-start, flex-start);

                    @include respond-to('m-768') {
                        padding-bottom: 16px;
                    }

                    .head {
                        @include flex(row, center, center);
                        padding-bottom: 8px;

                        md-icon {
                            color: #1E5FA6;

                            @include respond-to('m-768') {
                                display: none;
                            }
                        }

                        b {
                            padding-left: 8px;

                            @include respond-to('m-768') {
                                padding-left: initial;
                            }
                        }
                    }

                    .text {
                        padding-left: 34px;

                        @include respond-to('m-768') {
                            padding-left: initial;
                        }
                    }
                }
            }

            .cta {
                margin: 0;
                padding-top: 40px;
            }
        }

        .specific-features {
            padding: 40px;
            background-color: #f3f4f7;

            h2 {
                margin-top: 0;
            }

            .text {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 40px;
                align-items: start;

                @include respond-to('m-768') {
                    grid-template-columns: 1fr;
                }

                .list {
                    span {
                        display: list-item;
                        list-style-position: inside;
                    }
                }
            }
        }
    }
}