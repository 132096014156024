.connector {
    .padding {
        padding: 40px;
    }

    .vertical-spacer {
        padding: 20px 0;
    }

    h2 {
        margin-top: 0;
    }

    .brush {
        background-image: url('images/illustrations/connector/line-brush.svg');
        background-size: cover;
    }

    .brush-2 {
        background-image: url('images/illustrations/connector/line-brush-2.svg');
        background-size: cover;
    }

    header {
        background-color: #0a2540;
        color: #f4f3f7;

        h1 {
            padding-bottom: 24px;
        }

        .flex {
            @include flex(row, center, flex-start);
            gap: 40px;

            .text {
                flex: 1 1 0;

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                flex: 1 1 0;
                @include respond-to('t-992') {
                    display: none;
                }

                p {
                    margin: 0;

                    img {
                        width: 90%;
                        height: auto;
                    }
                }
            }
        }
    }

    .why {
        .reasons {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 40px;

            @include respond-to('d-1024') {
                grid-template-columns: repeat(2, 1fr);
            }

            @include respond-to('m-768') {
                grid-template-columns: 1fr;
            }

            .reason {
                .img {
                    @include flex(row, center, flex-start);
                    overflow: hidden;
                    margin: 3rem 0 0;

                    img {
                        width: 80%;

                        @include respond-to('d-1024') {
                            width: 40%;
                        }

                        @include respond-to('m-374') {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .cta {
            margin: 0;
        }
    }

    .customer-care {
        background-color: #0a2540;
        color: #f4f3f7;

        .customer-care-container {
            @include flex(row, flex-start, flex-start);
            gap: 40px;

            .text {
                flex: 2 1 0;

                @include respond-to('t-992') {
                    flex: 1 1 0;
                }

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                flex: 1 1 0;

                @include respond-to('m-768') {
                    display: none;
                }

                p {
                    text-align: right;
                    margin: 0;

                    img {
                        width: 90%;
                        height: auto;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .collection {
        .flex {
            @include flex(row, flex-end, flex-start);
            flex-direction: row;
            gap: 40px;

            @include respond-to('m-768') {
                display: block;
            }

            .text {
                flex: 2 1 0;

                @include respond-to('t-992') {
                    flex: 1 1 0;
                }

                .cta {
                    margin: 0;

                    @include respond-to('m-768') {
                        text-align: center;
                    }
                }
            }

            .illustration {
                flex: 1 1 0;

                p {
                    text-align: center;
                    margin: 0;

                    img {
                        width: 72%;
                        height: auto;

                        @include respond-to('d-1024') {
                            width: 80%;
                        }

                        @include respond-to('m-768') {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .connect {
        background-color: #0a2540;
        color: #f4f3f7;

        .flexibility-container {
            @include flex(row, center, flex-start);
            gap: 40px;

            @include respond-to('t-992') {
                flex-direction: column;
            }

            .text,
            .illustration {
                flex: 1 1 0;
            }

            .text {
                .cta {
                    margin: 0;
                }
            }

            .illustration {
                p {
                    text-align: right;
                    margin: 0;

                    img {
                        width: 90%;
                        height: auto;

                        @include respond-to('t-992') {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .faq {
        .faq-list {
            details {
                overflow: hidden;
                border-top: none;

                &[open] {
                    md-icon {
                        transform: rotate(90deg);
                        transition-property: transform;
                    }
                }

                summary {
                    display: grid;
                    cursor: pointer;

                    &:hover {
                        outline: none;

                        p {
                            background-color: rgba(0, 0, 0, 0.03);
                        }
                    }

                    .question {
                        padding: 24px 0;
                        margin: 0;

                        @include flex(row, center, flex-start);
                        gap: 40px;
                    }
                }
            }
        }
    }
}