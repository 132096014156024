@import "~@fontsource/fira-sans/latin-300.css";
@import "~@fontsource/fira-sans/latin-400.css";
@import "~@fontsource/fira-sans/latin-500.css";
@import "~@fontsource/fira-sans/latin-600.css";


@mixin font-face-woff2($family-name, $font-path, $weight: normal, $style: normal, $font-display: swap) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.woff2') format('woff2');
        font-weight: $weight;
        font-style: $style;
        font-display: $font-display;
    }
}

// https://fonts.google.com/icons 05-07-2022
$material-symbols-path: 'assets/fonts';
@include font-face-woff2(Material Symbols Outlined, '#{$material-symbols-path}/material-symbols-outlined', 400, normal, block);

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
