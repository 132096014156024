.landing-connector {
    .page-content {
        overflow: hidden;
        display: grid;
        grid-template-rows: 1fr auto;

        .logo-boxtal {
            @include flex(row, center, flex-end);
            min-height: 56px;
            position: relative;

            p {
                margin: 0;
                padding: 0 40px;

                img {
                    height: auto;
                    width: 160px;
                }
            }
        }

        header {
            background-color: #faf3fc;
            position: relative;

            &:after {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: -20vw;
                left: -20vw;
                width: 100vw;
                height: 100vw;
                border-radius: 50%;
                background-color: #F7D8FF;
            }

            .white-circle {
                position: absolute;
                width: 100%;
                height: 100%;
                overflow: hidden;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -20vw;
                    left: -20vw;
                    width: 100vw;
                    height: 100vw;
                    border-radius: 50%;
                    background-color: #ffffff;
                }
            }

            .boxtal {
                padding: 40px;

                .intro {
                    position: relative;
                    z-index: 1;

                    h1 {
                        .amazon {
                            img {
                                width: 160px;
                                height: auto;
                                padding-top: 10px;

                                @include respond-to('t-992') {
                                    width: 126px;
                                }

                                @include respond-to('m-768') {
                                    width: 94px;
                                    padding-top: 13px;
                                }
                            }
                        }

                        .backmarket {
                            img {
                                width: 338px;
                                height: auto;
                                padding-bottom: 18px;

                                @include respond-to('t-992') {
                                    width: 274px;
                                    padding-bottom: 12px;
                                }

                                @include respond-to('m-768') {
                                    width: 194px;
                                    padding-top: 8px;
                                }
                            }
                        }

                        .cdiscount {
                            img {
                                width: 234px;
                                height: auto;
                                padding-bottom: 18px;

                                @include respond-to('t-992') {
                                    width: 194px;
                                    padding-bottom: 14px;
                                }

                                @include respond-to('m-768') {
                                    width: 140px;
                                    padding-top: 10px;
                                }
                            }
                        }

                        .etsy {
                            img {
                                width: 118px;
                                height: auto;
                                padding-bottom: 10px;

                                @include respond-to('t-992') {
                                    width: 92px;
                                    padding-bottom: 6px;
                                }

                                @include respond-to('m-768') {
                                    width: 68px;
                                    padding-top: 6px;
                                }
                            }
                        }

                        .manomano {
                            img {
                                width: 362px;
                                height: auto;
                                padding-bottom: 20px;

                                @include respond-to('t-992') {
                                    width: 300px;
                                    padding-bottom: 12px;
                                }

                                @include respond-to('m-768') {
                                    width: 228px;
                                    padding-top: 6px;
                                }
                            }
                        }

                        .prestashop {
                            img {
                                width: 315px;
                                height: auto;
                                padding-bottom: 16px;

                                @include respond-to('t-992') {
                                    width: 260px;
                                }

                                @include respond-to('m-768') {
                                    width: 194px;
                                    padding-top: 12px;
                                }
                            }
                        }

                        .shopify {
                            img {
                                width: 222px;
                                height: auto;
                                padding-bottom: 20px;

                                @include respond-to('t-992') {
                                    width: 170px;
                                    padding-top: 6px;
                                }

                                @include respond-to('m-768') {
                                    width: 120px;
                                    padding-bottom: 12px;
                                }
                            }
                        }

                        .wix {
                            img {
                                width: 100px;
                                height: auto;
                                padding-bottom: 18px;

                                @include respond-to('t-992') {
                                    width: 80px;
                                    padding-top: 4px;
                                }

                                @include respond-to('m-768') {
                                    width: 60px;
                                    padding-bottom: 10px;
                                }
                            }
                        }

                        .woocommerce {
                            img {
                                width: 268px;
                                height: auto;

                                @include respond-to('t-992') {
                                    width: 232px;
                                    padding-top: 2px;
                                }

                                @include respond-to('m-768') {
                                    width: 160px;
                                    padding-top: 6px;
                                }
                            }
                        }
                    }
                }

                .zone-cta {
                    padding: 40px 0 16px 0;
                    @include flex(row, center, flex-start);
                    gap: 80px;

                    @include respond-to('m-768') {
                        padding: 40px 0 0 0;
                    }

                    @include respond-to('m-768') {
                        @include flex(column, flex-start, center);
                        gap: 40px;
                    }

                    .boxtal-connector {
                        padding-left: 33px;
                        @include flex(row, center, flex-start);

                        .truck {
                            position: relative;
                            @include flex(row, center, center);

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 96px;
                                height: 96px;
                                border-radius: 50%;
                                background-color: rgba(1, 104, 194, 0.4);
                            }

                            img {
                                padding-right: 8px;
                                width: 36px;
                                height: 36px;
                            }
                        }

                        .add {
                            position: relative;
                            z-index: 1;
                        }

                        .trolley {
                            position: relative;
                            @include flex(row, center, center);

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 96px;
                                height: 96px;
                                border-radius: 50%;
                                background-color: rgba(1, 104, 194, 0.4);
                            }

                            img {
                                padding-left: 8px;
                                width: 36px;
                                height: 36px;
                            }
                        }
                    }
                }
            }
        }

        .connector {
            padding: 40px 40px 16px 40px;

            .connector-container {
                @include flex(row, flex-start, flex-start);
                gap: 80px;

                @include respond-to('t-992') {
                    gap: 40px;
                }

                @include respond-to('m-768') {
                    @include flex(column, flex-start, flex-start);
                    gap: 0;
                }

                h2 {
                    margin-top: 0;
                    position: relative;
                }

                ul {
                    margin: 0;
                    padding: 80px 0 0 0;
                    list-style-type: none;

                    @include respond-to('m-768') {
                        padding: 0;
                    }

                    li {
                        @include flex(row, flex-start, flex-start);
                        gap: 8px;

                        .color {
                            color: #1E5FA6;
                            flex-shrink: 0;
                        }

                        > span {
                            display: inline-block;
                            padding-bottom: 4px;
                        }
                    }
                }
            }
        }

        footer {
            height: 240px;
            grid-row-start: 2;
            grid-row-end: 3;
            @include flex(column, center, flex-end);

            .footer-img {
                width: 100%;
                height: 100%;
                background: url('images/background/landing/landing-connectors/image-footer-over-1310px.png') no-repeat;
                background-size: cover;

                @include respond-to('d-1310') {
                    background-image: url('images/background/landing/landing-connectors/image-footer-1310px.png');
                }

                @include respond-to('t-992') {
                    background-image: url('images/background/landing/landing-connectors/image-footer-992px.png');
                }

                @include respond-to('m-768') {
                    background-image: url('images/background/landing/landing-connectors/image-footer-768px.png');
                }
            }
        }
    }
}

.landing-solution-expedition {
    .page-content {
        background-image: url('images/background/landing/landing-shipping-solution.jpg');
        background-size: cover;
        overflow: hidden;

        .logo-boxtal {
            @include flex(row, center, flex-end);
            min-height: 56px;

            p {
                position: relative;
                margin: 0;
                padding: 0 40px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -140px;
                    right: -50px;
                    width: 320px;
                    height: 320px;
                    border-radius: 50%;
                    border: 4px solid #17375f;
                    background-color: #ffffff;
                    z-index: 1;

                    @include respond-to('d-1024') {
                        top: -100px;
                        right: -16px;
                        width: 240px;
                        height: 240px;
                    }

                    @include respond-to('t-992') {
                        display: none;
                    }
                }

                img {
                    position: absolute;
                    right: 40px;
                    top: 32px;
                    z-index: 2;
                    height: auto;
                    width: 180px;

                    @include respond-to('d-1024') {
                        right: 30px;
                        top: 24px;
                        width: 160px;
                    }

                    @include respond-to('t-992') {
                        position: static;
                    }
                }
            }
        }

        .intro {
            padding: 40px;
            background-color: #ffffff;

            .subtitle {
                text-align: center;
            }

            .ctas {
                padding-top: 24px;

                p {
                    margin: 0;
                    @include flex(row, center, flex-start);
                    gap: 40px;

                    @include respond-to('m-768') {
                        @include flex(column, center, flex-start);
                        gap: 32px;
                    }

                    a {
                        @include respond-to('m-768') {
                            width: 100%;
                        }

                        @include respond-to('m-768') {
                            md-filled-button,
                            md-elevated-button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .carriers {
            padding: 32px 40px;
            background-color: rgba(168, 122, 76, 0.5);

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(8, 9%);
                grid-auto-rows: minmax(40px, 80px);
                grid-gap: 4%;

                @include respond-to('t-992') {
                    @include flex(row, center, space-between);
                    gap: 16px;
                    flex-wrap: wrap;
                }

                li {
                    padding: 0;
                    @include flex(row, center, center);

                    img {
                        width: 100%;
                        height: auto;
                        mix-blend-mode: multiply;

                        &.sogp,
                        &.upse {
                            max-height: 60px;
                            width: auto;
                        }

                        @include respond-to('t-992') {
                            width: 80px;
                        }
                    }
                }
            }
        }

        .boxtal {
            background-color: rgba(246, 230, 214, 0.5);

            .boxtal-container {
                @include flex(row, flex-start, space-between);
                gap: 80px;

                @include respond-to('m-768') {
                    @include flex(column);
                    gap: 40px;
                }

                p {
                    margin: 0;
                    padding: 40px;
                    height: 100%;
                    background: rgba(255, 254, 253, 0.66);
                }
            }
        }

        .benefits {
            padding: 40px;

            .container-benefits {
                @include flex(row, flex-start, flex-start);
                gap: 40px;

                @include respond-to('t-992') {
                    @include flex(column, flex-start, flex-start);
                    gap: 0;
                }

                h2 {
                    margin-top: 0;
                }

                ul {
                    background: rgba(255, 254, 253, 0.5);
                    list-style-type: none;
                    padding: 32px;
                    margin: 0;
                    width: 70%;

                    @include respond-to('t-992') {
                        width: initial;
                    }

                    li {
                        @include flex(row, flex-start, flex-start);
                        gap: 8px;

                        .add {
                            flex-shrink: 0;
                            padding-top: 2px;
                        }
                    }
                }
            }
        }
    }
}

.landing-drom {
    .page-content {
        @include flex(column);
        height: 100%;

        .heading-logo {
            display: none;

            @include respond-to('d-1024') {
                display: block;

                @include flex(row, center, flex-start);
                min-height: 56px;
                background-color: #ffffff;

                p {
                    margin: 0;
                    padding: 0 40px;

                    img {
                        height: auto;
                        width: 160px;
                    }
                }
            }
        }

        main {
            @include flex(row, flex-start);
            flex-grow: 1;

            @include respond-to('d-1024') {
                @include flex(column, flex-start, flex-start);
            }

            .text-container {
                background-image: url('images/background/landing/landing-drom/landing-drom-over-1310px.jpg');
                background-size: cover;
                height: 100%;
                flex-grow: 1;
                @include flex(column, null, space-between);

                @include respond-to('d-1024') {
                    width: 100%;
                    background-image: url('images/background/landing/landing-drom/landing-drom-1310px.jpg');
                }

                @include respond-to('t-992') {
                    background-image: url('images/background/landing/landing-drom/landing-drom-992px.jpg');
                }

                @include respond-to('m-768') {
                    background-image: url('images/background/landing/landing-drom/landing-drom-768px.jpg');
                }

                .text {
                    margin-top: 56px;
                    padding: 40px;
                    background-color: rgba(255, 255, 255, 0.7);

                    @include respond-to('d-1024') {
                        padding: 40px 0;
                    }

                    p {
                        display: block;
                        width: fit-content;
                        padding: 8px 32px;
                        margin: 24px 0 0 40px;
                        border-radius: 24px;
                        background-color: #e1f1f8;

                        @media (max-width: 1450px) {
                            margin: 24px 0 0 0;
                        }

                        & + p {
                            margin-left: 80px;

                            @media (max-width: 1450px) {
                                margin-left: 0;
                            }
                        }
                    }

                    h1, p {
                        @include respond-to('d-1024') {
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                    }

                    p {

                        @include respond-to('d-1024') {
                            width: initial;
                            border-radius: initial;
                            background-color: initial;
                            margin: initial;
                        }
                    }
                }

                .carriers {
                    margin-bottom: 40px;
                    padding: 40px 40px;
                    background-color: rgba(223, 243, 249, 0.6);

                    @include respond-to('d-1024') {
                        margin-bottom: 0;
                    }

                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;

                        @include flex(row, center, space-between);
                        gap: 16px;
                        flex-wrap: wrap;

                        li {
                            padding: 0;
                            @include flex(row, center, center);

                            img {
                                width: 100px;
                                height: auto;
                                filter: grayscale(50%);

                                &.sogp,
                                &.upse {
                                    max-height: 60px;
                                    width: auto;
                                }

                                @include respond-to('t-992') {
                                    width: 80px;
                                }
                            }
                        }
                    }
                }
            }

            .funnel {
                width: 640px;
                height: 100%;

                @media (max-width: 1600px) {
                    width: 480px;
                }

                @media (max-width: 1450px) {
                    width: 420px;
                }

                @include respond-to('d-1024') {
                    width: 100%;
                }

                .logo-boxtal {
                    @include flex(row, center, flex-end);
                    min-height: 56px;
                    background-color: #ffffff;

                    @include respond-to('d-1024') {
                        display: none;
                    }

                    p {
                        margin: 0;
                        padding: 0 40px;

                        img {
                            height: auto;
                            width: 160px;
                        }
                    }
                }

                .iframe-container:has(.oops) {
                    padding: 40px;
                }
            }
        }
    }
}

.landing-cotation-ulule {

    h2 {
        margin-top: 0;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background-color: #e7f8fc;
        padding: 0 40px 40px 40px;

        @include respond-to('t-992') {
            padding: 0;
        }

        .intro {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, flex-start);

            @include respond-to('t-992') {
                border-radius: 0;
                gap: 16px;
            }

            .text {
                padding: 40px 0;
                @include flex(column, flex-start, space-around);

                @include respond-to('t-992') {
                    padding: 40px 40px 0 40px;
                }

                h1 {
                    img {
                        width: auto;
                        height: 64px;
                        padding-bottom: 12px;

                        @include respond-to('t-992') {
                            height: 48px;
                            margin-left: 8px;
                            padding-bottom: 0;
                        }

                        @include respond-to('m-768') {
                            height: 40px;
                        }
                    }
                }

                .desktop {
                    @include flex(column, flex-start, flex-start);
                    row-gap: 24px;

                    @include respond-to('t-992') {
                        display: none;
                    }

                    p {
                        display: block;
                        width: fit-content;
                        padding: 16px 32px 16px 24px;
                        margin: 0;
                        background-color: #ffffff;
                        border-radius: 40px;
                        box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                        @include flex(row, flex-end, flex-start);
                        gap: 8px;

                        img {
                            width: 16px;
                            height: auto;
                            margin-bottom: 16px;
                            transform: rotate(110deg);
                        }
                    }
                }
            }

            .container-iframe {
                background-color: #ffffff;
                margin-top: 48px;
                border-radius: $bdrs-normal;
                min-width: 360px;

                @include respond-to('t-992') {
                    margin-top: 0;
                    border-radius: initial;
                    min-width: initial;
                }

                &:has(.oops) {
                    background-color: initial;
                    padding-left: 40px;
                }
            }

            .tablet-mobile-text {
                display: none;

                @include respond-to('t-992') {
                    display: block;
                    padding: 24px 40px 40px 40px;

                    p {
                        @include flex(row, center, flex-start);
                        gap: 8px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        md-icon {
                            color: #F01446;
                        }
                    }
                }
            }
        }
    }

    .statistics-container {
        padding: 8px 40px;

        @include respond-to('t-992') {
            padding: 24px 40px;
        }

        .stats {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                align-items: flex-start;
            }

            .stat {
                @include flex(row, center, flex-start);
                gap: 40px;
                flex: 1;

                @include respond-to('t-992') {
                    width: 100%;
                    gap: 24px;
                }

                & + .stat {
                    border-left: 1px solid rgba(0, 0, 0, 0.87);
                    padding-left: 40px;

                    @include respond-to('t-992') {
                        border-left: none;
                        padding: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .automation-container {
        background-color: #e7f8fc;
        padding: 40px;

        .automation {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(row, flex-end, space-between);
            gap: 80px;

            @include respond-to('d-1310') {
                gap: 40px;
            }

            .img, .text {
                flex: 1;
            }

            .img {
                margin: 0;
                @include respond-to('t-992') {
                    display: none;
                }

                img {
                    width: 80%;
                }
            }

            .text {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .services-boxtal {
        padding: 40px;

        .services-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .services {
                @include flex(row, flex-start, flex-start);
                flex-wrap: wrap;
                column-gap: 40px;

                @include respond-to('t-992') {
                    display: block;
                }

                .service {
                    width: calc(50% - 20px);

                    @include respond-to('t-992') {
                        width: 100%;
                    }

                    h3 {
                        @include flex(row, center, flex-start);
                        gap: 8px;

                        md-icon {
                            color: #F01446;
                        }
                    }
                }
            }
        }

        .cta {
            @include flex(row, center, center);
            margin: 0;
            padding-top: 24px;

            @include respond-to('t-992') {
                justify-content: flex-start;
            }
        }
    }

    .boxtal-container {
        background-color: #e7f8fc;
        padding: 40px;

        .boxtal {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(column, flex-start, flex-start);

            .cta {
                margin: 0;
                padding-top: 24px;
            }
        }
    }
}

.landing-bulk-import-ulule {

    h2 {
        margin-top: 0;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background-color: #e7f8fc;
        padding: 40px;

        .intro {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            h1 {
                img {
                    width: auto;
                    height: 64px;
                    padding-bottom: 12px;

                    @include respond-to('t-992') {
                        height: 48px;
                        margin-left: 8px;
                        padding-bottom: 0;
                    }

                    @include respond-to('m-768') {
                        height: 40px;
                    }
                }
            }

            .header-arguments {
                padding-top: 40px;
                @include flex(column, flex-start, flex-start);
                gap: 40px;

                p {
                    display: block;
                    width: fit-content;
                    padding: 16px 32px 16px 24px;
                    margin: 0;
                    background-color: #ffffff;
                    border-radius: 40px;
                    box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                    @include flex(row, flex-start, flex-start);
                    gap: 8px;

                    img {
                        width: 16px;
                        height: auto;
                        margin-bottom: 16px;
                        transform: rotate(110deg);
                    }
                }
            }

            .bulk-import-link {
                margin: 0;
                padding-top: 40px;
            }
        }
    }

    .statistics-container {
        padding: 8px 40px;

        @include respond-to('t-992') {
            padding: 24px 40px;
        }

        .stats {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                align-items: flex-start;
            }

            .stat {
                @include flex(row, center, flex-start);
                gap: 40px;
                flex: 1;

                @include respond-to('t-992') {
                    width: 100%;
                    gap: 24px;
                }

                & + .stat {
                    border-left: 1px solid rgba(0, 0, 0, 0.87);
                    padding-left: 40px;

                    @include respond-to('t-992') {
                        border-left: none;
                        padding: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .how-to {
        background-color: #e7f8fc;
        padding: 40px;

        .how-to-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .steps {
                @include flex(column, flex-start, flex-start);
                gap: 40px;

                .step {
                    @include flex(row, flex-start, flex-start);
                    gap: 24px;
                    margin: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .number {
                        @include flex(row, center, center);
                        flex-shrink: 0;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #18a5d6;
                        color: #ffffff;
                        font-weight: 500;
                    }

                    .text {
                        padding-top: 6px;
                    }
                }
            }
        }
    }

    .services-boxtal {
        padding: 40px;

        .services-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .services {
                @include flex(row, flex-start, flex-start);
                flex-wrap: wrap;
                column-gap: 40px;

                @include respond-to('t-992') {
                    display: block;
                }

                .service {
                    width: calc(50% - 20px);

                    @include respond-to('t-992') {
                        width: 100%;
                    }

                    h3 {
                        @include flex(row, center, flex-start);
                        gap: 8px;

                        md-icon {
                            color: #F01446;
                        }
                    }
                }
            }
        }

        .cta {
            @include flex(row, center, center);
            margin: 0;
            padding-top: 24px;

            @include respond-to('t-992') {
                justify-content: flex-start;
            }
        }
    }

    .boxtal-container {
        background-color: #e7f8fc;
        padding: 40px;

        .boxtal {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(column, flex-start, flex-start);

            .cta {
                margin: 0;
                padding-top: 24px;
            }
        }
    }
}

.landing-ulule-boxtal {

    h2 {
        margin-top: 0;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background-color: #e7f8fc;
        padding: 40px;

        .intro {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            h1 {
                img {
                    width: auto;
                    height: 64px;
                    padding-bottom: 12px;

                    @include respond-to('t-992') {
                        height: 48px;
                        margin-left: 8px;
                        padding-bottom: 0;
                    }

                    @include respond-to('m-768') {
                        height: 40px;
                    }
                }
            }

            .header-arguments {
                padding-top: 32px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 40px;
                align-items: start;

                @include respond-to('t-992') {
                    @include flex(row, flex-start, center);
                    flex-wrap: wrap;
                    gap: 40px;
                }

                &:nth-child(1), &:nth-child(2) {
                    @include respond-to('t-992') {
                        width: calc(40% - 20px);
                    }
                }

                p {
                    width: fit-content;
                    padding: 16px 32px 16px 24px;
                    margin: 0;
                    background-color: #ffffff;
                    border-radius: $bdrs-normal;
                    box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                    @include flex(row, flex-start, flex-start);
                    gap: 16px;

                    @include respond-to('t-992') {
                        width: calc(43% - 20px);
                    }

                    @media screen and (max-width: 896px) {
                        width: 100%;
                    }

                    img {
                        width: 16px;
                        height: auto;
                        margin-bottom: 16px;
                        transform: rotate(110deg);
                    }
                }
            }

            .create-account {
                margin: 0;
                padding-top: 40px;
            }
        }
    }

    .statistics-container {
        padding: 8px 40px;

        @include respond-to('t-992') {
            padding: 24px 40px;
        }

        .stats {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                align-items: flex-start;
            }

            .stat {
                @include flex(row, center, flex-start);
                gap: 40px;
                flex: 1;

                @include respond-to('t-992') {
                    width: 100%;
                    gap: 24px;
                }

                & + .stat {
                    border-left: 1px solid rgba(0, 0, 0, 0.87);
                    padding-left: 40px;

                    @include respond-to('t-992') {
                        border-left: none;
                        padding: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .services-boxtal {
        background-color: #e7f8fc;
        padding: 40px;

        .services-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .services {
                @include flex(row, flex-start, flex-start);
                flex-wrap: wrap;
                column-gap: 40px;

                @include respond-to('t-992') {
                    display: block;
                }

                .service {
                    width: calc(50% - 20px);

                    @include respond-to('t-992') {
                        width: 100%;
                    }

                    h3 {
                        @include flex(row, center, flex-start);
                        gap: 8px;

                        md-icon {
                            color: #F01446;
                        }
                    }
                }
            }
        }

        .cta {
            @include flex(row, center, center);
            margin: 0;
            padding-top: 24px;

            @include respond-to('t-992') {
                justify-content: flex-start;
            }
        }
    }

    .boxtal-container {
        padding: 40px;

        .boxtal {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(column, flex-start, flex-start);

            .cta {
                margin: 0;
                padding-top: 24px;
            }
        }
    }

    .cotation-container {
        background-color: #e7f8fc;
        padding: 40px;

        @include respond-to('m-768') {
            padding: 40px 0 0;
        }

        .cotation {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            h2 {
                padding: 0 40px 32px 0;

                @include respond-to('m-768') {
                    padding: 0 40px 32px 40px;
                }
            }

            .container-iframe {
                background-color: #ffffff;
                border-radius: $bdrs-normal;

                @include respond-to('m-768') {
                    border-radius: initial;
                }

                &:has(.oops) {
                    background-color: initial;

                    @include respond-to('m-768') {
                        padding: 0 0 40px 40px;
                    }
                }
            }
        }
    }
}

.landing-merchant-ulule {
    .container-ulule {
        margin: 0px auto;
        max-width: 1200px;
        width: 100%;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background-color: #254C63;
        background-image: url('images/background/landing/landing-ulule/ulule-cotation-bulk.jpg');
        background-size: 100%;
        padding: 40px 0;

        @include respond-to('t-992') {
            background-image: none;
        }

        .intro {
            background-color: #ffffff;
            border-radius: $bdrs-normal;

            @include respond-to('t-992') {
                border-radius: 0;
            }

            .padding {
                padding: 40px;
            }

            .text {
                h1 {
                    img {
                        margin-left: 40px;
                        width: auto;
                        height: 64px;
                        padding-bottom: 12px;

                        @include respond-to('t-992') {
                            height: 48px;
                            margin-left: 8px;
                            padding-bottom: 0;
                        }

                        @include respond-to('m-768') {
                            height: 40px;
                        }
                    }
                }
            }

            .flex-grid-container {
                padding-top: 16px;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 8px;
                grid-row-gap: 16px;

                @include respond-to('t-992') {
                    grid-template-columns: repeat(5, 1fr);
                }

                @include respond-to('m-768') {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width: 550px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                p {
                    margin: 0;

                    a {
                        text-decoration: none;
                        @include flex(column, center, center);
                        gap: 8px;

                        .img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                width: 72px;
                                height: auto;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .boxtal {
        .padding {
            padding: 40px;

            h2 {
                margin-top: 0;
            }

            .benefit-list {
                list-style-type: none;

                @include respond-to('t-992') {
                    padding-left: 0;
                }


                li {
                    @include flex(row, flex-start, flex-start);
                    gap: 8px;
                    padding-bottom: 8px;

                    md-icon {
                        flex-shrink: 0;
                    }
                }
            }

            .carrier-list {
                list-style-type: none;
                padding: 16px 0 0 0;
                margin: 0;

                @include flex(row, center, space-between);
                gap: 16px;
                flex-wrap: wrap;

                li {
                    padding: 0;
                    @include flex(row, center, center);

                    img {
                        width: 100px;
                        height: auto;
                        filter: grayscale(50%);

                        &.sogp,
                        &.upse {
                            max-height: 60px;
                            width: auto;
                        }

                        @include respond-to('t-992') {
                            width: 80px;
                        }
                    }
                }
            }
        }
    }
}

.landing-connectors-v2 {

    h2 {
        margin-top: 0;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background-color: #e4edfe;
        padding: 40px;

        .title {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
        }

        .intro {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, flex-start);

            @include respond-to('t-992') {
                border-radius: 0;
                gap: 16px;
            }

            .text {
                padding: 40px 0 0 0;
                @include flex(column, flex-start, space-around);
                gap: 32px;

                .arguments {
                    @include flex(column, flex-start, flex-start);
                    row-gap: 24px;

                    p {
                        display: block;
                        width: fit-content;
                        padding: 16px 32px 16px 24px;
                        margin: 0;
                        background-color: #ffffff;
                        border-radius: 40px;
                        box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                        @include flex(row, flex-end, flex-start);
                        gap: 8px;
                    }
                }

                .connect-shop {
                    margin: 0;
                    padding-top: 40px;

                    @include respond-to('t-992') {
                        padding-top: 32px;
                    }

                    @include respond-to('m-768') {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .illustration {
                width: 50%;

                @include respond-to('t-992') {
                    width: 100%;
                }

                p {
                    margin: 0;
                    padding-top: 40px;

                    @include respond-to('t-992') {
                        text-align: center;
                    }

                    img {
                        width: 100%;

                        @include respond-to('t-992') {
                            width: 70%;
                        }

                        @include respond-to('m-768') {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .statistics-container {
        padding: 8px 40px;

        @include respond-to('t-992') {
            padding: 24px 40px;
        }

        .stats {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                align-items: flex-start;
            }

            .stat {
                @include flex(row, center, flex-start);
                gap: 40px;
                flex: 1;

                @include respond-to('t-992') {
                    width: 100%;
                    gap: 24px;
                }

                & + .stat {
                    border-left: 1px solid rgba(0, 0, 0, 0.87);
                    padding-left: 40px;

                    @include respond-to('t-992') {
                        border-left: none;
                        padding: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .services-boxtal {
        background-color: #e4edfe;
        padding: 40px;

        .services-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .services {
                @include flex(row, flex-start, flex-start);
                flex-wrap: wrap;
                column-gap: 40px;

                @include respond-to('t-992') {
                    display: block;
                }

                .service {
                    width: calc(50% - 20px);

                    @include respond-to('t-992') {
                        width: 100%;
                    }

                    h3 {
                        @include flex(row, center, flex-start);
                        gap: 8px;

                        md-icon {
                            color: #F01446;
                        }
                    }
                }
            }
        }

        .cta {
            @include flex(row, center, center);
            margin: 0;
            padding-top: 24px;

            @include respond-to('t-992') {
                justify-content: flex-start;
            }

            @include respond-to('m-768') {
                justify-content: center;
            }
        }
    }

    .faq-container {
        padding: 40px 0;

        .faq {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(column, flex-start, flex-start);

            h2 {
                padding: 0 40px 24px 40px;
            }

            .faq-list-container {
                padding: 0 40px;
                width: calc(100% - 80px);

                @include respond-to('m-768') {
                    padding: 0;
                    width: 100%;
                }

                .faq-list {
                    box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                    width: 100%;

                    @include respond-to('m-768') {
                        box-shadow: none;
                    }

                    details {
                        overflow: hidden;
                        border-top: none;

                        &:first-child {
                            border-radius: 4px 4px 0 0;

                            @include respond-to('m-768') {
                                border-radius: 0;
                            }
                        }

                        &:last-child {
                            border-radius: 0 0 4px 4px;

                            @include respond-to('m-768') {
                                border-radius: 0;
                            }
                        }

                        &[open] {

                            md-icon {
                                transform: rotate(90deg);
                                transition-property: transform;
                            }
                        }


                        summary {
                            display: grid;
                            cursor: pointer;

                            &:hover {
                                outline: none;

                                p {
                                    background-color: #e4edfe;
                                }
                            }

                            .question {
                                padding: 24px;
                                background-color: #EFF3FF;
                                margin: 0;
                                @include flex(row, center, flex-start);
                                gap: 40px;

                                @include respond-to('m-768') {
                                    padding: 24px 40px;
                                }
                            }
                        }


                        .answer {
                            padding: 24px;
                            background-color: #ffffff;

                            @include respond-to('m-768') {
                                padding: 24px 40px;
                            }
                        }
                    }
                }
            }

            .cta {
                margin: 0;
                padding-top: 40px;

                @include respond-to('m-768') {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.landing-international {

    .logo-boxtal {
        background-color: #ffffff;
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    .horizontal-padding {
        padding: 0 40px;
    }

    .horizontal-padding-mobile {
        padding: 0 40px;

        @include respond-to('m-768') {
            padding: 0;
        }
    }

    .vertical-padding {
        padding: 40px 0;
    }

    .vertical-spacer {
        padding: 16px 0;

        @include respond-to('m-768') {
            padding: 8px 0;
        }
    }

    h1, h2 {
        margin-top: 0;

        @include respond-to('m-768') {
            text-align: center;
        }
    }

    .underline {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, rgba(195, 21, 50, 0.8) 50%);
    }

    header {
        color: #ffffff;
        background: url('images/illustrations/landing/landing_inter.jpg');
        background-size: cover;
        background-position-y: -160px;
        background-repeat: no-repeat;
        background-color: #000209;

        .bdrs {
            border-radius: $bdrs-normal;
            background-color: #ffffff;

            @include respond-to('m-768') {
                box-shadow: initial;
                border-radius: initial;
            }

            &:has(.oops) {
                width: fit-content;

                @include respond-to('m-768') {
                    background-color: initial;
                    padding-left: 40px;
                }
            }
        }
    }

    .boxtal {
        .services {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 40px;
            grid-row-gap: 40px;

            @include respond-to('t-992') {
                grid-template-columns: repeat(2, 1fr);
            }

            @include respond-to('m-768') {
                grid-template-columns: repeat(1, 1fr);
            }

            .icon {
                margin: 0;
                @include flex(row, center, center);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #17375f;
                color: #ffffff;
            }

            h3 {
                display: inline;
                box-shadow: inset 0 -0.3em 0 rgba(195, 21, 50, 0.3);

                & + p {
                    margin-top: 1.38rem;
                }
            }
        }
    }

    .delivery {
        text-align: center;
        background-color: #002142;
        color: #ffffff;

        table {
            margin: 0 auto;
            border-collapse: collapse;
            @include respond-to('m-768') {
                width: 100%;
            }

            th, td {
                padding: 16px 0;
            }

            th {
                border-bottom: 1px solid #ffffff;
            }

            th:nth-child(even),
            td:nth-child(even) {
                padding: 16px;
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
            }

            th:first-child,
            td:first-child {
                padding: 16px 16px 16px 0;
            }

            th:last-child,
            td:last-child {
                padding: 16px 0 16px 16px;
            }

            th, td + td {
                text-align: center;
            }

            tr:first-child {
                border-bottom: 1px solid #ffffff;
            }

            .vertical-align {
                @include flex-row-to-column('m-768', center, flex-start);

                .icon {
                    @include flex(row, center, center);
                    flex-shrink: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    color: #17375f;
                    margin-right: 8px;

                    @include respond-to('m-768') {
                        margin-bottom: 16px;
                    }

                    & + span {
                        padding: 0 8px;

                        @include respond-to('m-768') {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    .holidays {
        text-align: center;

        table {
            margin: 0 auto;

            @include respond-to('m-768') {
                width: 100%;
            }

            td {
                padding: 16px;
                text-align: center;

                &:nth-child(odd) {
                    text-align: left;

                    span {
                        display: inline;
                        box-shadow: inset 0 -0.3em 0 rgba(195, 21, 50, 0.3);
                    }
                }

                &:first-child {
                    padding: 16px 16px 16px 0;
                }

                &:last-child {
                    padding: 16px 0 16px 16px;
                }

                &:nth-child(2) {
                    padding: 16px 120px 16px 16px;

                    @include respond-to('m-768') {
                        padding: 16px;
                    }
                }
            }
        }
    }

    .prohibited-goods {
        background-color: #002142;
        color: #ffffff;
    }

    .customs {
        .customs-formalities.enum {
            width: 100%;
            @include flex(row, flex-start, flex-start);
            flex-wrap: wrap;
            gap: 40px;

            p {
                @include flex(column, flex-start, flex-start);
                max-width: 30%;

                @include respond-to('t-992') {
                    max-width: initial;
                    width: calc(50% - 40px);
                }

                @include respond-to('m-768') {
                    width: 100%;
                }

                .number {
                    @include flex(row, center, center);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #17375f;
                    color: #ffffff;
                }

                .text {
                    padding-top: 1rem;
                }
            }
        }

        .cta {
            margin: 0;
        }
    }

    .customer-advices {
        background-color: #002142;
        color: #ffffff;

        .icon {
            margin: 0;
            @include flex(row, center, center);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ffffff;
            color: #17375f;
        }

        h3 {
            display: inline;
            box-shadow: inset 0 -0.3em 0 rgba(195, 21, 50, 0.7);

            & + p {
                margin-top: 1.38rem;
            }
        }

        .advices {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 40px;
            grid-row-gap: 40px;

            @include respond-to('t-992') {
                grid-template-columns: repeat(2, 1fr);
            }

            @include respond-to('m-768') {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }

    .faq {
        .faq-list-container {
            width: calc(100% - 80px);

            @include respond-to('m-768') {
                padding: 0;
                width: 100%;
            }

            .faq-list {
                box-shadow: rgba(89, 84, 78, 0.12) 1px 2px 14px 0px;
                width: 100%;

                @include respond-to('m-768') {
                    box-shadow: none;
                }

                details {
                    overflow: hidden;
                    border-top: none;

                    &:first-child {
                        border-radius: 4px 4px 0 0;

                        @include respond-to('m-768') {
                            border-radius: 0;
                        }
                    }

                    &:last-child {
                        border-radius: 0 0 4px 4px;

                        @include respond-to('m-768') {
                            border-radius: 0;
                        }
                    }

                    &[open] {

                        md-icon {
                            transform: rotate(90deg);
                            transition-property: transform;
                        }
                    }


                    summary {
                        display: grid;
                        cursor: pointer;

                        &:hover {
                            outline: none;
                            background-color: rgba(30, 95, 166, 0.01);
                        }

                        .question {
                            padding: 24px;
                            margin: 0;
                            @include flex(row, center, flex-start);
                            gap: 40px;

                            @include respond-to('m-768') {
                                padding: 24px 40px;
                            }
                        }
                    }


                    .answer {
                        margin: 0;
                        padding: 24px;
                        background-color: #ffffff;
                        background-color: rgba(30, 95, 166, 0.1);


                        @include respond-to('m-768') {
                            padding: 24px 40px;
                        }
                    }
                }
            }
        }
    }
}

.landing-expedition-pro {

    h2 {
        margin-top: 0;
    }

    .logo-boxtal {
        @include flex(row, center, flex-start);
        min-height: 56px;

        p {
            margin: 0;
            padding: 0 40px;

            img {
                height: auto;
                width: 160px;
            }
        }
    }

    header {
        background: url('images/background/landing/expedition-pro.jpg');
        background-size: cover;
        padding: 40px;
        color: #ffffff;

        .title {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
        }

        .intro {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                border-radius: 0;
                gap: 16px;
            }

            .text {
                padding: 40px 0 0 0;
                gap: 24px;
                @include flex(column, flex-start, space-around);

                .arguments {
                    @include flex(column, flex-start, flex-start);
                    row-gap: 32px;

                    p {
                        display: block;
                        border-radius: 36px;
                        padding: 8px 32px;
                        margin: 0;
                        width: fit-content;
                        background-color: rgba(18, 69, 123, 0.85);
                        text-align: center;
                    }
                }

                .send {
                    margin: 0;
                    padding-top: 40px;

                    @include respond-to('t-992') {
                        padding-top: 32px;
                    }

                    @include respond-to('m-768') {
                        width: 100%;
                        text-align: center;
                    }
                }
            }

            .illustration {
                width: 50%;
                text-align: center;

                @include respond-to('t-992') {
                    width: 100%;
                }

                p {
                    margin: 0;
                    padding-top: 40px;

                    @include respond-to('t-992') {
                        text-align: center;
                    }

                    img {
                        width: 80%;

                        @include respond-to('t-992') {
                            width: 60%;
                        }

                        @include respond-to('m-768') {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }

    .statistics-container {
        padding: 8px 40px;
        background-color: #78a4d5;

        @include respond-to('t-992') {
            padding: 24px 40px;
        }

        .stats {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            gap: 40px;
            @include flex-row-to-column('t-992', stretch, space-between);

            @include respond-to('t-992') {
                align-items: flex-start;
            }

            .stat {
                @include flex(row, center, flex-start);
                gap: 40px;
                flex: 1;

                @include respond-to('t-992') {
                    width: 100%;
                    gap: 24px;
                }

                & + .stat {
                    border-left: 1px solid rgba(0, 0, 0, 0.87);
                    padding-left: 40px;

                    @include respond-to('t-992') {
                        border-left: none;
                        padding: 0;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    .services-boxtal {
        background-color: #070e14;
        color: #ffffff;
        padding: 40px;

        .services-container {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;

            .services {
                @include flex(row, flex-start, flex-start);
                flex-wrap: wrap;
                column-gap: 120px;

                @include respond-to('t-992') {
                    display: block;
                }

                .service {
                    width: calc(50% - 60px);

                    @include respond-to('t-992') {
                        width: 100%;
                    }

                    h3 {
                        @include flex(row, center, flex-start);
                        gap: 8px;
                        color: #4e8acb;
                    }

                    p {
                        color: #c7def9;
                    }
                }
            }
        }

        .cta {
            @include flex(row, center, center);
            margin: 0;
            padding-top: 40px;

            @include respond-to('t-992') {
                justify-content: flex-start;
            }

            @include respond-to('m-768') {
                justify-content: center;
            }
        }
    }

    .faq-container {
        padding: 40px 0;
        background-color: #78a4d5;

        .faq {
            margin: 0 auto;
            max-width: 1200px;
            width: 100%;
            @include flex(column, flex-start, flex-start);

            h2 {
                padding: 0 40px 24px 40px;
            }

            .faq-list-container {
                padding: 0 40px;
                width: calc(100% - 80px);

                @include respond-to('m-768') {
                    padding: 0;
                    width: 100%;
                }

                .faq-list {
                    width: 100%;

                    @include respond-to('m-768') {
                        box-shadow: none;
                    }

                    details {
                        overflow: hidden;
                        border-top: none;
                        background-color: #e8f1fb;

                        &:first-child {
                            border-radius: 4px 4px 0 0;

                            @include respond-to('m-768') {
                                border-radius: 0;
                            }
                        }

                        &:last-child {
                            border-radius: 0 0 4px 4px;

                            @include respond-to('m-768') {
                                border-radius: 0;
                            }
                        }

                        &[open] {

                            md-icon {
                                transform: rotate(90deg);
                                transition-property: transform;
                            }
                        }


                        summary {
                            display: grid;
                            cursor: pointer;

                            &:hover {
                                outline: none;

                                p {
                                    background-color: #bedafa;
                                }
                            }

                            .question {
                                padding: 24px;
                                background-color: #b5d5fa;
                                margin: 0;
                                @include flex(row, center, flex-start);
                                gap: 40px;

                                @include respond-to('m-768') {
                                    padding: 24px 40px;
                                }
                            }
                        }


                        .answer {
                            padding: 24px;
                            background-color: #e8f1fb;

                            @include respond-to('m-768') {
                                padding: 24px 40px;
                            }
                        }
                    }

                    md-divider {
                        color: #78a4d5;
                    }
                }
            }

            .cta {
                margin: 0;
                padding-top: 40px;

                @include respond-to('m-768') {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

.ex-landing5 {
    .page-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-bar {
            @include flex(row, center, space-between);
            height: 56px;
            padding: 0 40px;

            .logo-boxtal {
                margin: 0;

                img {
                    height: 26px;
                    width: auto;
                }
            }

            .carriers-logo {
                @include flex(row, center, space-between);
                gap: 8px;
                margin: 0;
                width: 70%;

                @include respond-to('m-768') {
                    display: none;
                }

                span {
                    display: block;
                    text-align: center;
                    padding: 4px 0 0 0;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;

                    @include respond-to('d-1310') {
                        width: 66px;
                        height: 66px;
                        line-height: 66px;
                    }

                    @include respond-to('d-1024') {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                    }

                    .monr,
                    .sogp{
                        width: 55%;
                    }

                    .fedx {
                        width: 80%;
                    }

                    .upse {
                        width: 52%;
                    }

                    .tnte {
                        width: 70%;
                    }

                    .dhle {
                        width: 90%;
                    }
                }
            }
        }

        .main {
            position: relative;
            height: 100%;

            @include respond-to('t-992') {
                height: initial;
            }

            .padding {
                padding: 40px;
                height: calc(100% - 80px);
                @include flex(row, center, center);

                @include respond-to('t-992') {
                    padding: 0;
                    height: initial;
                }

                .container-main {
                    @include flex(row, flex-start, flex-start);
                    gap: 80px;

                    @include respond-to('t-992') {
                        display: block;
                    }

                    .text {
                        flex: 2 1 0;

                        h1 {
                            .chrp {
                                color: #009adf;
                            }

                            .copr {
                                color: #8e34aa;
                            }

                            .pofr, .poste {
                                color: #eb8f02;
                            }

                            .dhle {
                                color: #ffae00;
                            }

                            .fedx, .tnte {
                                color: #ff6200;
                            }

                            .upse {
                                color: #ac8401;
                            }

                            .h24,.bulky, .monr {
                                color: #9c0a46;
                            }

                            .sogp {
                                color: #9bac27;
                            }
                        }
                    }

                    .container-iframe {
                        flex: 1 1 0;
                    }

                    .text {
                        @include respond-to('t-992') {
                            padding: 40px 40px 0 40px;
                        }

                        .logo-argument {
                            @include flex(row, center, flex-start);
                            gap: 40px;

                            @include respond-to('m-768') {
                                flex-direction: column;
                                gap: 0;
                            }

                            .text-logo {
                                @include flex(row, center, center);
                                width: 100px;
                                height: 100px;
                                flex-shrink: 0;
                                border-radius: 50%;

                                &.dhle {
                                    img {
                                        width: 110%;
                                    }
                                }

                                &.fedx {
                                    img {
                                        width: 80%;
                                    }
                                }

                                &.relay {
                                    img {
                                        width: 100%;
                                    }
                                }

                                img {
                                    width: 60%;
                                }

                                & + p {
                                    @include respond-to('m-768') {
                                        align-self: flex-start;
                                    }
                                }
                            }
                        }
                    }

                    .container-iframe {
                        background-color: #ffffff;
                        border-radius: $bdrs-normal;
                        width: 100%;
                        min-width: 370px;

                        @include respond-to('t-992') {
                            border-radius: initial;
                            box-shadow: none;
                        }

                        &:has(> .oops){
                            border-radius: initial;
                            box-shadow: none;

                            .shipping-app-link {
                                padding: 40px;
                            }
                        }
                    }
                }
            }

            picture {
                position: absolute;
                bottom: 0px;
                z-index: -1;

                @include respond-to('t-992') {
                    position: initial;
                }
            }
        }

        footer {
            .bottom {
                background-color: transparent;
                padding: 0 40px 8px;

                .container {
                    ul {
                        li {
                            a {
                                color: rgba(0, 0, 0, 0.87);
                            }
                        }
                    }
                }
            }
        }
    }
}